import { BrowserRouter as Router, Route, Routes, Link, useParams } from "react-router-dom";
import { useState, useEffect, useRef } from 'react';
import { db, storage } from './firebase.js';
import { collection, onSnapshot, doc, getDoc, where, query, getDocs } from 'firebase/firestore';
import Header from './Head';
import './App.css';
import { getDownloadURL, ref } from '@firebase/storage';  // Ensure this line is at the beginning of your file
import VideoPlayer from 'video.js';
import 'video.js/dist/video-js.css';



function App() {
    return (
        <Router>
            <Header />
            <div className="content">
                <Routes>
                    <Route path="/:genre" element={<MoviesByGenre />} />
                    <Route path="/:genre/:slug" element={<MovieDetails />} />
                    <Route path="/animes" element={<AnimesList />} />
                    <Route path="/animes/:animeSlug" element={<AnimeDetails />} />
                    <Route path="/animes/:animeSlug/:season/:episode" element={<EpisodeDetails />} />
                    <Route path="/series" element={<SeriesList />} />
                    <Route path="/series/:seriesSlug" element={<SeriesDetails />} />
                    <Route path="/series/:seriesSlug/:season/:episode" element={<EpisodeSeries />} />
                </Routes>
            </div>
        </Router>
    );
}

const ITEMS_PER_PAGE = 20;

function MoviesByGenre() {
    const [movies, setMovies] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const { genre } = useParams();

    useEffect(() => {
        const filmesCollectionRef = collection(db, 'filmes');
        const genreQuery = query(filmesCollectionRef, where("genre", "==", genre));

        const unsubscribe = onSnapshot(genreQuery, (querySnapshot) => {
            const moviesArray = [];
            querySnapshot.forEach((doc) => {
                moviesArray.push({ id: doc.id, ...doc.data() });
            });
            setMovies(moviesArray);
        }, (error) => {
            console.error("Erro ao buscar filmes do gênero: ", error);
        });

        return () => unsubscribe();
    }, [genre]);

    const totalPages = Math.ceil(movies.length / ITEMS_PER_PAGE);
    
    const displayedMovies = movies.slice((currentPage - 1) * ITEMS_PER_PAGE, currentPage * ITEMS_PER_PAGE);

    return (
        <div>
            <div className="movies-by-genre">
                {displayedMovies.map(movie => (
                    <div className="borda-filme" key={movie.id}>
                        <Link to={`${movie.slug}`}>
                            <div className="movie-lateral">
                                <img src={movie.image} alt={movie.titulo} />
                            </div>
                        </Link>
                    </div>
                ))}
            </div>
            {totalPages > 1 && (
                <div className="pagination">
                    {Array.from({ length: totalPages }).map((_, index) => (
                        <button
                            key={index}
                            className={currentPage === index + 1 ? 'active' : ''}
                            onClick={() => setCurrentPage(index + 1)}
                        >
                            {index + 1}
                        </button>
                    ))}
                </div>
            )}
        </div>
    );
}


function MovieDetails() {
  const [movieDetails, setMovieDetails] = useState(null);
  const [videoURL, setVideoURL] = useState(null);
  const { slug } = useParams();

  useEffect(() => {
      const fetchMovieDetails = async () => {
          const movieDocRef = doc(db, 'filmes', slug);
          const docSnapshot = await getDoc(movieDocRef);

          if (docSnapshot.exists()) {
              const data = docSnapshot.data();
              setMovieDetails({ ...data, id: docSnapshot.id });

              const videoRef = ref(storage, data.url_video); 
              try {
                  const url = await getDownloadURL(videoRef);
                  setVideoURL(url);
              } catch (error) {
                  console.error("Erro ao obter a URL de streaming do vídeo: ", error);
              }
          } else {
              console.log("No such movie!");
          }
      };

      fetchMovieDetails();
  }, [slug]);

  const videoRef = useRef(null);

  useEffect(() => {
      console.log("videoRef current value:", videoRef.current);
      console.log("Current videoURL:", videoURL);

      if (videoURL) {
          const player = VideoPlayer(videoRef.current);
          console.log("Initialized player:", player);

          import('videojs-audio-tracks/dist/videojs-audio-tracks.min.js').then(() => {
              console.log("Plugin imported successfully");
              if (player && player.audioTracks) {
                  console.log("audioTracks method is available on player");
              } else {
                  console.log("audioTracks method is NOT available on player");
              }
              player.audioTracks();
          }).catch(error => {
              console.error("Error importing plugin:", error);
          });

          return () => {
              if (player) {
                  console.log("Disposing player");
                  player.dispose();
              }
          };
      }
  }, [videoURL]);


  if (!movieDetails) {
    return <div>Carregando...</div>;
}

return (
    <div className="movie-details">
         <div data-vjs-player>
            <video ref={videoRef} className="video-js vjs-default-skin" controls>
                <source src={videoURL} type="video/mp4" />
                <track kind="alternative" src="path-to-audio-track-1.mp4" srcLang="en" label="English" default />
                <track kind="alternative" src="path-to-audio-track-2.mp4" srcLang="es" label="Spanish" />
                <track kind="subtitles" src="path-to-subtitle-en.vtt" srcLang="en" label="English" default />
                <track kind="subtitles" src="path-to-subtitle-es.vtt" srcLang="es" label="Spanish" />

                Seu navegador não suporta o elemento de vídeo.
            </video>
        </div>
        <div>
              <div className="borda">
                      <div className="movie-lateral-1">
                          <img src={movieDetails.image} alt={movieDetails.titulo} />
                      </div>
                      <div className="movie-lateral-2">
                          <div className="inline"><h3>Título:</h3><h4>{movieDetails.titulo}</h4></div>
                          <div className="inline"><h3>Gênero:</h3><p>{movieDetails.genre}</p></div>
                          <div className="inline"><h3>IMDb:</h3><p>{movieDetails.IMDb}</p></div>
                          <div className="inline"><h3>Lançamento:</h3><p>{movieDetails.Lançamento}</p></div>
                          <h3>Sinopse:</h3><p>{movieDetails.Sinopse}</p>
                      </div>
              </div>
      </div>
    </div>
);
}


function AnimesList() {
    const [animes, setAnimes] = useState([]);
    // Comment out or remove the line below if not planning to use pagination in this component
    // const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        const animesCollectionRef = collection(db, 'animes');

        const unsubscribe = onSnapshot(animesCollectionRef, (querySnapshot) => {
            const animesArray = [];
            querySnapshot.forEach((doc) => {
                if(doc.data().genre === "animes") {
                    animesArray.push({ id: doc.id, ...doc.data() });
                }
            });
            setAnimes(animesArray);
        }, (error) => {
            console.error("Erro ao buscar animes: ", error);
        });

        return () => unsubscribe();
    }, []);

    // Comment out or remove the line below if not planning to use pagination in this component
    // const totalPages = Math.ceil(animes.length / ITEMS_PER_PAGE);
    
    // If not using pagination, you can just return the animes directly
    const displayedAnimes = animes; // or keep the slice if planning to use pagination

    return (
        <div>
            <div className="movies-by-genre">
                {displayedAnimes.map(anime => (
                    <div className="borda-filme" key={anime.id}>
                        <Link to={'/animes/' + anime.slug}>
                            <div className="movie-lateral">
                                <img src={anime.image} alt={anime.titulo} />
                            </div>
                        </Link>
                    </div>
                ))}
            </div>
        </div>
    );
}


function AnimeDetails() {
    const { animeSlug } = useParams();
    const [anime, setAnime] = useState(null);
    const [seasons, setSeasons] = useState([]);
    const [activeSeason, setActiveSeason] = useState(null); // Novo estado


    useEffect(() => {
        const fetchAnimeDetails = async () => {
            const animeDocRef = doc(db, 'animes', animeSlug);
            const animeDoc = await getDoc(animeDocRef);
            if (animeDoc.exists()) {
                setAnime(animeDoc.data());
        
                // Fetch seasons for the anime
                const seasonsCollectionRef = collection(db, 'animes', animeSlug, 'temporadas');
                const seasonsSnapshot = await getDocs(seasonsCollectionRef);
                    
                // For each season, fetch the episodes
                const allSeasons = [];
                for (let seasonDoc of seasonsSnapshot.docs) {
                    const seasonData = seasonDoc.data();
                    const episodesCollectionRef = collection(db, 'animes', animeSlug, 'temporadas', seasonData.slug, 'episodios');
                    const episodesSnapshot = await getDocs(episodesCollectionRef);
                    allSeasons.push({
                        ...seasonData,
                        episodes: episodesSnapshot.docs.map(episodeDoc => episodeDoc.data())
                    });
                }
                setSeasons(allSeasons);
                console.log("Temporadas recuperadas:", allSeasons);
            } else {
                console.log("No such anime!");
            }
        };
    
        fetchAnimeDetails();
    }, [animeSlug]);
    
    

    return (
        <div className="anime-details">
            {anime && (
                <div className="details-container">
                    <div className="image-container">
                        <img src={anime.image} alt={anime.titulo} /> {/* Supondo que anime.imageUrl contém a URL da imagem */}
                    </div>
                    <div className="info-container">
                        <div className="season-tabs">
                        {seasons.map(season => (
                            <button 
                                key={season.slug}
                                onClick={() => setActiveSeason(season.slug)} 
                                className={activeSeason === season.slug ? 'active' : ''}
                            >
                                {season.titulo.replace('-', ' ')}
                            </button>    
                        ))}
                        </div>

                        {activeSeason && (
                            <div className="season-episodes">
                               {seasons.find(s => s.slug === activeSeason).episodes.map((episode) => (
                                    <div className="episode-x">
                                        <Link key={episode.slug} to={'/animes/' + animeSlug + '/' + activeSeason + '/' + episode.slug}>
                                            {episode.titulo}
                                        </Link>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
}


function EpisodeDetails() {
    const { animeSlug, season, episode } = useParams();
    const [episodeDetails, setEpisodeDetails] = useState(null);
    const [videoURL, setVideoURL] = useState('');

    useEffect(() => {
        const fetchEpisodeDetails = async () => {
            const episodeDocRef = doc(db, 'animes', animeSlug, 'temporadas', season, 'episodios', episode);
            const episodeDoc = await getDoc(episodeDocRef);
            if (episodeDoc.exists()) {
                const episodeData = episodeDoc.data();
                
                if(episodeData.url_video) {
                    let storagePath;
                    if (episodeData.url_video.startsWith('gs://')) {
                        storagePath = episodeData.url_video.replace('gs://', '').replace('bevoltec.appspot.com/', '');
                    } else {
                        storagePath = episodeData.url_video;
                    }
        
                    try {
                        const url = await getDownloadURL(ref(storage, storagePath));
                        setVideoURL(url);
                    } catch (error) {
                        console.error("Erro ao obter a URL de streaming do vídeo: ", error);
                    }
                } else {
                    console.error("url_video não está definido para este episódio:", episode);
                }
        
                setEpisodeDetails(episodeData);
            } else {
                console.log("No such episode!");
            }
        };
                

        fetchEpisodeDetails();
    }, [animeSlug, season, episode]);
    const videoRef = useRef(null);

    useEffect(() => {
        console.log("videoRef current value:", videoRef.current);
        console.log("Current videoURL:", videoURL);

        if (videoURL) {
            const player = VideoPlayer(videoRef.current);
            console.log("Initialized player:", player);

            import('videojs-audio-tracks/dist/videojs-audio-tracks.min.js').then(() => {
                console.log("Plugin imported successfully");
                if (player && player.audioTracks) {
                    console.log("audioTracks method is available on player");
                } else {
                    console.log("audioTracks method is NOT available on player");
                }
                player.audioTracks();
            }).catch(error => {
                console.error("Error importing plugin:", error);
            });

            return () => {
                if (player) {
                    console.log("Disposing player");
                    player.dispose();
                }
            };
        }
    }, [videoURL]);
    

    if (!episodeDetails) {
        return <div>Carregando...</div>;
    }

    return (
        <div className="episode-details">
            <div className="inline"><h2>{episodeDetails.titulo}</h2></div>
            <div data-vjs-player>
                <video ref={videoRef} className="video-js vjs-default-skin" controls>
                    <source src={videoURL} type="video/mp4" />
                    Seu navegador não suporta o elemento de vídeo.
                </video>
            </div>
            <div>
            </div>
        </div>
    );
}


function SeriesList() {
    const [series, setSeries] = useState([]);

    useEffect(() => {
        const seriesCollectionRef = collection(db, 'series');

        const unsubscribe = onSnapshot(seriesCollectionRef, (querySnapshot) => {
            const seriesArray = [];
            querySnapshot.forEach((doc) => {
                seriesArray.push({ id: doc.id, ...doc.data() });
            });
            setSeries(seriesArray);
        }, (error) => {
            console.error("Erro ao buscar séries: ", error);
        });

        return () => unsubscribe();
    }, []);

    return (
        <div>
            <div className="movies-by-genre">
                {series.map(serie => (
                    <div className="borda-filme2" key={serie.id}>
                        <Link to={'/series/' + serie.slug}>
                            <div className="movie-lateral">
                                <img src={serie.image} alt={serie.titulo} />
                            </div>
                        </Link>
                    </div>
                ))}
            </div>
        </div>
    );
}

function SeriesDetails() {
    const { seriesSlug } = useParams();
    const [serie, setSerie] = useState(null);
    const [seasons, setSeasons] = useState([]);
    const [activeSeason, setActiveSeason] = useState(null); 

    useEffect(() => {
        const fetchSeriesDetails = async () => {
            const serieDocRef = doc(db, 'series', seriesSlug);
            const serieDoc = await getDoc(serieDocRef);
            if (serieDoc.exists()) {
                setSerie(serieDoc.data());

                const seasonsCollectionRef = collection(db, 'series', seriesSlug, 'temporadas');
                const seasonsSnapshot = await getDocs(seasonsCollectionRef);

                const allSeasons = [];
                for (let seasonDoc of seasonsSnapshot.docs) {
                    const seasonData = seasonDoc.data();
                    const episodesCollectionRef = collection(db, 'series', seriesSlug, 'temporadas', seasonData.slug, 'episodios');
                    const episodesSnapshot = await getDocs(episodesCollectionRef);
                    allSeasons.push({
                        ...seasonData,
                        episodes: episodesSnapshot.docs.map(episodeDoc => episodeDoc.data())
                    });
                }
                setSeasons(allSeasons);
            } else {
                console.log("No such series!");
            }
        };

        fetchSeriesDetails();
    }, [seriesSlug]);

    return (
        <div className="series-details">
            {serie && (
                <div className="details-container">
                    <div className="image-container">
                        <img src={serie.image} alt={serie.titulo} /> {/* Supondo que serie.image contém a URL da imagem */}
                    </div>
                    <div className="info-container">
                        <div className="season-tabs">
                            {seasons.map(season => (
                                <button 
                                    key={season.slug}
                                    onClick={() => setActiveSeason(season.slug)} 
                                    className={activeSeason === season.slug ? 'active' : ''}
                                >
                                    {season.titulo.replace('-', ' ')}
                                </button>    
                            ))}
                        </div>

                        {activeSeason && (
                            <div className="season-episodes">
                                {seasons.find(s => s.slug === activeSeason).episodes.map((episode) => (
                                    <div className="episode-x">
                                        <Link key={episode.slug} to={'/series/' + seriesSlug + '/' + activeSeason + '/' + episode.slug}>
                                            {episode.titulo}
                                        </Link>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
}

function EpisodeSeries() {
    const { seriesSlug, season, episode } = useParams();
    const [episodeDetails, setEpisodeDetails] = useState(null);
    const [videoURL, setVideoURL] = useState('');

    useEffect(() => {
        const fetchEpisodeDetails = async () => {
            const episodeDocRef = doc(db, 'series', seriesSlug, 'temporadas', season, 'episodios', episode);
            const episodeDoc = await getDoc(episodeDocRef);
            if (episodeDoc.exists()) {
                const episodeData = episodeDoc.data();

                if(episodeData.url_video) {
                    let storagePath;
                    if (episodeData.url_video.startsWith('gs://')) {
                        storagePath = episodeData.url_video.replace('gs://', '').replace('bevoltec.appspot.com/', '');
                    } else {
                        storagePath = episodeData.url_video;
                    }

                    try {
                        const url = await getDownloadURL(ref(storage, storagePath));
                        setVideoURL(url);
                    } catch (error) {
                        console.error("Erro ao obter a URL de streaming do vídeo: ", error);
                    }
                } else {
                    console.error("url_video não está definido para este episódio:", episode);
                }

                setEpisodeDetails(episodeData);
            } else {
                console.log("No such episode!");
            }
        };

        fetchEpisodeDetails();
    }, [seriesSlug, season, episode]);

    const videoRef = useRef(null);

    useEffect(() => {
        console.log("videoRef current value:", videoRef.current);
        console.log("Current videoURL:", videoURL);

        if (videoURL) {
            const player = VideoPlayer(videoRef.current);
            console.log("Initialized player:", player);

            import('videojs-audio-tracks/dist/videojs-audio-tracks.min.js').then(() => {
                console.log("Plugin imported successfully");
                if (player && player.audioTracks) {
                    console.log("audioTracks method is available on player");
                } else {
                    console.log("audioTracks method is NOT available on player");
                }
                player.audioTracks();
            }).catch(error => {
                console.error("Error importing plugin:", error);
            });

            return () => {
                if (player) {
                    console.log("Disposing player");
                    player.dispose();
                }
            };
        }
    }, [videoURL]);
    

    if (!episodeDetails) {
        return <div>Carregando...</div>;
    }

    return (
        <div className="episode-details">
            <div className="inline"><h2>{episodeDetails.titulo}</h2></div>
            <div data-vjs-player>
                <video ref={videoRef} className="video-js vjs-default-skin" controls>
                    <source src={videoURL} type="video/mp4" />
                    Seu navegador não suporta o elemento de vídeo.
                </video>
            </div>
            <div>
            </div>
        </div>
    );
}



export default App;
